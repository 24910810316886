@import url('variables.css');
/* HeroSection.css */
.hero-section {
  background-image: url('../public/hero.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh; /* Set the height of the hero section to 100% of viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff; /* White text color */
}

.hero-content {
  padding: 20px;
}

.hero-content h1 {
  font-size: 3rem;
  color: var(--lighter-colour);
}

