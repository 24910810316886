@import 'variables.css';

.about-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  background-color: var(--lighter-colour);

  /* background-color: var(--lighter-colour); */
  display: flex;
  justify-content: start;
  align-items: start;
}

.about-content {
  color: var(--dark-colour);
  font-weight: 500;
  padding: 40px 20px 0;
  max-width: 600px; /* Limit the width of the content */
  margin: 0 auto; /* Center the content horizontally */
}


