/* App.css */
body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

.app {
  overflow-x: hidden;
  position: relative;
}

/* Styling for HeroSection */
/* Styling for SocialSection */
/* Styling for AboutSection */
/* Styling for ContactSection */
