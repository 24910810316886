@import 'variables.css';

.contact-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* background-color: var(--silver-colour); */
  background-color: var(--lighter-colour);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.contact-content {
  color: var(--dark-colour);
  font-weight: 500;
}

.contact-content a {
  color: var(--darker-silver);
  transition: color 0.3s;
}

.contact-content a:hover {
  color: var(--dark-colour);

}