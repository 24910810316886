@import url('variables.css');

/* SocialSection.css */
.social-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  /* background-color: var(--silver-colour) */
  display: flex;
  justify-content: center;
  align-items: center;
  
  z-index: 1;
}

.social-icons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%); */
  display: flex; /* Set the display property to flex */
  justify-content: center; /* Center the icons horizontally */
}

.social-icons li {
  margin: 0 10px; /* Adjust spacing between icons */
}

.social-icons a {
  color: var(--silver-colour);
  font-size: 2rem;
  margin: 0 10px; /* Adjust spacing between icons */
  transition: color 0.3s;
}

.social-icons a:hover {
  color: var(--dark-colour);

}
